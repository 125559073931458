import React from 'react'

export const ColorLogo = () => {
  return (  
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={125} x="0px" y="0px" viewBox="0 0 194 50" style={{enableBackground: 'new 0 0 194 50'}} xmlSpace="preserve">
      <g fill="#5C0F8B">
        <polygon points="1.4,9.4 1.4,21 15.1,21 15.1,28.6 1.4,28.6 1.4,41.1 21.7,41.1 21.7,49.2 34.2,49.2 18.1,9.4 	"/>
        <polygon points="42.1,49.2 58.8,49.2 50.4,28.9 	"/>
        <path d="M1.4,0.8L1.4,0.8L1.4,0.8z"/>
        <polyline points="64.8,0.9 56.4,23 62.5,38.5 66.7,26.9 66.7,0.8 	"/>
        <polyline points="44.9,0.8 50.5,17 56,0.8 	"/>
        <polyline points="24.7,0.8 24.9,1.2 38.5,38.5 44.5,23 36.5,1.2 36.3,0.8 	"/>
        <polygon points="98,17.1 98,18.7 86,18.7 86,0.8 97.8,0.8 97.8,2.3 87.7,2.3 87.7,8.8 96.5,8.8 96.5,10.3 87.7,10.3 
          87.7,17.1 	"/>
        <polygon points="104.8,18.7 99.4,5.5 101.2,5.5 105.7,17.1 110.2,5.5 111.9,5.5 106.5,18.7 	"/>
        <path d="M117.9,18.9c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.8-0.9-1.3c-0.2-0.5-0.3-1-0.3-1.6
          c0-0.6,0.1-1.1,0.4-1.6c0.3-0.5,0.6-0.9,1.1-1.3c0.5-0.4,1.1-0.6,1.7-0.8c0.7-0.2,1.4-0.3,2.2-0.3c0.7,0,1.4,0.1,2,0.2
          c0.7,0.1,1.3,0.3,1.8,0.5v-1.2c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.6-1-2.7-1c-0.7,0-1.4,0.1-2.1,0.4c-0.7,0.3-1.4,0.7-2.1,1.2L114.4,7
          c1.7-1.1,3.3-1.7,4.9-1.7c1.6,0,2.9,0.5,3.9,1.4c0.9,0.9,1.4,2.2,1.4,3.8v5.9c0,0.5,0.2,0.7,0.6,0.7v1.5c-0.3,0.1-0.5,0.1-0.7,0.1
          c-0.4,0-0.8-0.1-1-0.3c-0.2-0.2-0.4-0.5-0.4-0.9l0-1c-0.6,0.8-1.4,1.4-2.3,1.8C119.9,18.7,118.9,18.9,117.9,18.9 M118.3,17.6
          c0.9,0,1.7-0.2,2.5-0.5c0.7-0.3,1.3-0.8,1.7-1.3c0.2-0.2,0.3-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.1-0.5v-2.1c-0.6-0.2-1.2-0.4-1.8-0.5
          c-0.6-0.1-1.2-0.2-1.9-0.2c-1.2,0-2.2,0.3-3,0.8c-0.8,0.5-1.1,1.2-1.1,2c0,0.4,0.1,0.8,0.2,1.1c0.2,0.4,0.4,0.7,0.7,0.9
          c0.3,0.3,0.6,0.5,1,0.6C117.4,17.5,117.8,17.6,118.3,17.6"/>
        <path d="M140,18.7h-1.7v-7.3c0-1.6-0.2-2.7-0.7-3.4c-0.5-0.7-1.2-1.1-2.1-1.1c-0.5,0-1,0.1-1.5,0.3
          c-0.5,0.2-1,0.4-1.4,0.8c-0.4,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.6,1-0.7,1.5v8h-1.7V5.5h1.6v3c0.5-1,1.3-1.7,2.3-2.3
          c1-0.6,2.1-0.9,3.3-0.9c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.5,1.1,0.7,1.8c0.1,0.7,0.2,1.5,0.2,2.3V18.7z"/>
        <path d="M148.6,18.9c-1,0-2-0.2-3-0.5c-1-0.3-1.8-0.8-2.5-1.5l0.8-1.2c0.7,0.6,1.5,1.1,2.3,1.4
          c0.8,0.3,1.6,0.5,2.5,0.5c1.1,0,1.9-0.2,2.5-0.6c0.6-0.4,0.9-1,0.9-1.8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.5-0.7-0.6
          c-0.3-0.2-0.7-0.3-1.2-0.5c-0.5-0.1-1.1-0.3-1.7-0.4c-0.8-0.2-1.4-0.4-2-0.6c-0.6-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.6-0.5-0.8-0.9
          c-0.2-0.3-0.3-0.8-0.3-1.3c0-0.7,0.1-1.2,0.4-1.7c0.3-0.5,0.6-0.9,1.1-1.2c0.4-0.3,1-0.6,1.6-0.7c0.6-0.2,1.2-0.3,1.9-0.3
          c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2,1.3l-0.8,1c-0.5-0.5-1.1-0.9-1.8-1.1c-0.7-0.3-1.4-0.4-2.2-0.4c-0.5,0-0.9,0-1.3,0.1
          c-0.4,0.1-0.7,0.2-1,0.4c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6
          c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.9,0.3,1.5,0.4c0.8,0.2,1.6,0.4,2.2,0.6c0.6,0.2,1.2,0.4,1.6,0.7c0.4,0.3,0.8,0.6,1,1
          c0.2,0.4,0.3,0.9,0.3,1.4c0,1.2-0.5,2.2-1.4,2.9C151.4,18.6,150.2,18.9,148.6,18.9"/>
        <path d="M163.5,18c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.5,0.2-0.9,0.2
          c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.8c-0.2-0.3-0.2-0.7-0.2-1.1V6.9
          h-1.8V5.5h1.8V1.1h1.7v4.4h3v1.4h-3v8.9c0,0.5,0.2,0.8,0.5,1.1c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.9-0.1,1.3-0.2
          c0.4-0.2,0.6-0.3,0.7-0.3L163.5,18z"/>
        <path d="M171.5,18.9c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.3-1.3-2.2c-0.3-0.8-0.5-1.7-0.5-2.6
          c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.2c0.6-0.6,1.3-1.1,2.1-1.5c0.8-0.4,1.7-0.6,2.6-0.6c0.9,0,1.8,0.2,2.6,0.6
          c0.8,0.4,1.5,0.9,2.1,1.5c0.6,0.6,1,1.3,1.4,2.2c0.3,0.8,0.5,1.7,0.5,2.6c0,0.9-0.2,1.8-0.5,2.6c-0.3,0.8-0.8,1.5-1.3,2.2
          c-0.6,0.6-1.3,1.1-2.1,1.5C173.4,18.7,172.5,18.9,171.5,18.9 M166.8,12.2c0,0.7,0.1,1.4,0.4,2.1c0.3,0.6,0.6,1.2,1,1.7
          c0.4,0.5,0.9,0.9,1.5,1.1c0.6,0.3,1.2,0.4,1.9,0.4s1.3-0.1,1.9-0.4c0.6-0.3,1.1-0.7,1.5-1.1c0.4-0.5,0.8-1,1-1.7
          c0.3-0.6,0.4-1.3,0.4-2.1c0-0.7-0.1-1.4-0.4-2.1c-0.3-0.6-0.6-1.2-1-1.7c-0.4-0.5-0.9-0.9-1.5-1.1c-0.6-0.3-1.2-0.4-1.9-0.4
          s-1.3,0.1-1.8,0.4c-0.6,0.3-1.1,0.7-1.5,1.1c-0.4,0.5-0.8,1.1-1,1.7C166.9,10.7,166.8,11.4,166.8,12.2"/>
        <path d="M192.6,18.7h-1.7v-7.3c0-1.6-0.2-2.7-0.7-3.4c-0.5-0.7-1.2-1.1-2.1-1.1c-0.5,0-1,0.1-1.5,0.3
          c-0.5,0.2-1,0.4-1.4,0.8c-0.4,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.6,1-0.7,1.5v8h-1.7V5.5h1.6v3c0.5-1,1.3-1.7,2.3-2.3
          c1-0.6,2.1-0.9,3.3-0.9c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.5,1.1,0.7,1.8c0.1,0.7,0.2,1.5,0.2,2.3V18.7z"/>
        <polygon points="92.7,31.1 94.4,31.1 96.9,37.5 99.4,31.1 101.1,31.1 98,38.8 101.4,46.9 107.9,31 109.8,31 
          102.2,48.9 100.7,48.9 96.9,40.1 93.1,48.9 91.6,48.9 84,31 85.9,31 92.4,46.9 95.8,38.8 	"/>
        <path d="M112.6,30.5h1.7V33h-1.7V30.5z M112.6,35.8h1.7v13.1h-1.7V35.8z"/>
        <path d="M117.8,42.3c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.3-2.2c0.6-0.6,1.3-1.1,2.1-1.4c0.8-0.4,1.7-0.5,2.7-0.5
          c1.2,0,2.3,0.3,3.3,0.8s1.6,1.3,2.1,2.3l-1.7,0.5c-0.4-0.7-0.9-1.2-1.6-1.6c-0.7-0.4-1.4-0.6-2.2-0.6c-0.7,0-1.3,0.1-1.9,0.4
          c-0.6,0.3-1.1,0.6-1.5,1.1c-0.4,0.5-0.8,1-1,1.7c-0.2,0.6-0.4,1.3-0.4,2.1c0,0.7,0.1,1.4,0.4,2.1c0.3,0.7,0.6,1.2,1,1.7
          c0.4,0.5,0.9,0.9,1.5,1.1c0.6,0.3,1.2,0.4,1.9,0.4c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.1-0.5c0.4-0.2,0.7-0.4,0.9-0.7
          c0.3-0.3,0.4-0.6,0.5-0.9l1.7,0.5c-0.2,0.5-0.5,0.9-0.8,1.3c-0.4,0.4-0.8,0.7-1.2,1c-0.5,0.3-1,0.5-1.6,0.7
          c-0.6,0.2-1.2,0.2-1.8,0.2c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.4-1.4-2.2C118,44.1,117.8,43.3,117.8,42.3"
          />
        <polygon points="142.6,48.9 137.8,42.2 134.9,44.8 134.9,48.9 133.2,48.9 133.2,30.5 134.9,30.5 134.9,43 142.3,35.8 
          144.3,35.8 138.9,41.2 144.5,48.9 	"/>
        <path d="M152.2,49.2c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.4-1.4-2.2c-0.3-0.8-0.5-1.7-0.5-2.6
          c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1.1,2.1-1.5c0.8-0.4,1.7-0.5,2.6-0.5c1,0,1.8,0.2,2.6,0.5
          c0.8,0.4,1.5,0.9,2.1,1.5c0.6,0.6,1,1.3,1.3,2.1c0.3,0.8,0.5,1.7,0.5,2.6v0.4c0,0.1,0,0.2,0,0.3h-11.2c0.1,0.7,0.2,1.4,0.5,2
          c0.3,0.6,0.6,1.1,1.1,1.6c0.4,0.4,0.9,0.8,1.5,1c0.6,0.2,1.2,0.4,1.8,0.4c0.4,0,0.8-0.1,1.3-0.2c0.4-0.1,0.8-0.3,1.2-0.5
          c0.4-0.2,0.7-0.5,1-0.7c0.3-0.3,0.5-0.6,0.7-1l1.5,0.4c-0.2,0.5-0.5,0.9-0.9,1.3c-0.4,0.4-0.8,0.7-1.3,1c-0.5,0.3-1,0.5-1.6,0.7
          C153.5,49.1,152.8,49.2,152.2,49.2 M157.1,41.7c0-0.7-0.2-1.3-0.5-1.9c-0.3-0.6-0.6-1.1-1.1-1.5c-0.4-0.4-0.9-0.7-1.5-1
          c-0.6-0.2-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1.1,0.6-1.5,1c-0.4,0.4-0.8,0.9-1,1.5c-0.3,0.6-0.4,1.2-0.5,1.9H157.1z
          "/>
        <path d="M168.5,37.3c-1.2,0-2.2,0.4-3,1c-0.9,0.6-1.5,1.4-1.8,2.5v8.2H162V35.8h1.6v3.2c0.5-1,1.1-1.8,2-2.4
          c0.8-0.6,1.7-0.9,2.6-0.9c0.1,0,0.2,0,0.3,0V37.3z"/>
      </g>
    </svg>
  )
}

export const WhiteLogo = ({ width, height }) => {
  return (  
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={width || 125} height={height || 150} x="0px" y="0px" viewBox="0 0 194 50" style={{enableBackground: 'new 0 0 194 50'}} xmlSpace="preserve">
      <g fill="#ffffff">
        <polygon points="1.4,9.4 1.4,21 15.1,21 15.1,28.6 1.4,28.6 1.4,41.1 21.7,41.1 21.7,49.2 34.2,49.2 18.1,9.4 	"/>
        <polygon points="42.1,49.2 58.8,49.2 50.4,28.9 	"/>
        <path d="M1.4,0.8L1.4,0.8L1.4,0.8z"/>
        <polyline points="64.8,0.9 56.4,23 62.5,38.5 66.7,26.9 66.7,0.8 	"/>
        <polyline points="44.9,0.8 50.5,17 56,0.8 	"/>
        <polyline points="24.7,0.8 24.9,1.2 38.5,38.5 44.5,23 36.5,1.2 36.3,0.8 	"/>
        <polygon points="98,17.1 98,18.7 86,18.7 86,0.8 97.8,0.8 97.8,2.3 87.7,2.3 87.7,8.8 96.5,8.8 96.5,10.3 87.7,10.3 
          87.7,17.1 	"/>
        <polygon points="104.8,18.7 99.4,5.5 101.2,5.5 105.7,17.1 110.2,5.5 111.9,5.5 106.5,18.7 	"/>
        <path d="M117.9,18.9c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.8-0.9-1.3c-0.2-0.5-0.3-1-0.3-1.6
          c0-0.6,0.1-1.1,0.4-1.6c0.3-0.5,0.6-0.9,1.1-1.3c0.5-0.4,1.1-0.6,1.7-0.8c0.7-0.2,1.4-0.3,2.2-0.3c0.7,0,1.4,0.1,2,0.2
          c0.7,0.1,1.3,0.3,1.8,0.5v-1.2c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.6-1-2.7-1c-0.7,0-1.4,0.1-2.1,0.4c-0.7,0.3-1.4,0.7-2.1,1.2L114.4,7
          c1.7-1.1,3.3-1.7,4.9-1.7c1.6,0,2.9,0.5,3.9,1.4c0.9,0.9,1.4,2.2,1.4,3.8v5.9c0,0.5,0.2,0.7,0.6,0.7v1.5c-0.3,0.1-0.5,0.1-0.7,0.1
          c-0.4,0-0.8-0.1-1-0.3c-0.2-0.2-0.4-0.5-0.4-0.9l0-1c-0.6,0.8-1.4,1.4-2.3,1.8C119.9,18.7,118.9,18.9,117.9,18.9 M118.3,17.6
          c0.9,0,1.7-0.2,2.5-0.5c0.7-0.3,1.3-0.8,1.7-1.3c0.2-0.2,0.3-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.1-0.5v-2.1c-0.6-0.2-1.2-0.4-1.8-0.5
          c-0.6-0.1-1.2-0.2-1.9-0.2c-1.2,0-2.2,0.3-3,0.8c-0.8,0.5-1.1,1.2-1.1,2c0,0.4,0.1,0.8,0.2,1.1c0.2,0.4,0.4,0.7,0.7,0.9
          c0.3,0.3,0.6,0.5,1,0.6C117.4,17.5,117.8,17.6,118.3,17.6"/>
        <path d="M140,18.7h-1.7v-7.3c0-1.6-0.2-2.7-0.7-3.4c-0.5-0.7-1.2-1.1-2.1-1.1c-0.5,0-1,0.1-1.5,0.3
          c-0.5,0.2-1,0.4-1.4,0.8c-0.4,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.6,1-0.7,1.5v8h-1.7V5.5h1.6v3c0.5-1,1.3-1.7,2.3-2.3
          c1-0.6,2.1-0.9,3.3-0.9c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.5,1.1,0.7,1.8c0.1,0.7,0.2,1.5,0.2,2.3V18.7z"/>
        <path d="M148.6,18.9c-1,0-2-0.2-3-0.5c-1-0.3-1.8-0.8-2.5-1.5l0.8-1.2c0.7,0.6,1.5,1.1,2.3,1.4
          c0.8,0.3,1.6,0.5,2.5,0.5c1.1,0,1.9-0.2,2.5-0.6c0.6-0.4,0.9-1,0.9-1.8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.5-0.7-0.6
          c-0.3-0.2-0.7-0.3-1.2-0.5c-0.5-0.1-1.1-0.3-1.7-0.4c-0.8-0.2-1.4-0.4-2-0.6c-0.6-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.6-0.5-0.8-0.9
          c-0.2-0.3-0.3-0.8-0.3-1.3c0-0.7,0.1-1.2,0.4-1.7c0.3-0.5,0.6-0.9,1.1-1.2c0.4-0.3,1-0.6,1.6-0.7c0.6-0.2,1.2-0.3,1.9-0.3
          c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.5,0.8,2,1.3l-0.8,1c-0.5-0.5-1.1-0.9-1.8-1.1c-0.7-0.3-1.4-0.4-2.2-0.4c-0.5,0-0.9,0-1.3,0.1
          c-0.4,0.1-0.7,0.2-1,0.4c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.4,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.4,0.6,0.6
          c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.9,0.3,1.5,0.4c0.8,0.2,1.6,0.4,2.2,0.6c0.6,0.2,1.2,0.4,1.6,0.7c0.4,0.3,0.8,0.6,1,1
          c0.2,0.4,0.3,0.9,0.3,1.4c0,1.2-0.5,2.2-1.4,2.9C151.4,18.6,150.2,18.9,148.6,18.9"/>
        <path d="M163.5,18c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.5,0.2-0.9,0.2
          c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.8c-0.2-0.3-0.2-0.7-0.2-1.1V6.9
          h-1.8V5.5h1.8V1.1h1.7v4.4h3v1.4h-3v8.9c0,0.5,0.2,0.8,0.5,1.1c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.9-0.1,1.3-0.2
          c0.4-0.2,0.6-0.3,0.7-0.3L163.5,18z"/>
        <path d="M171.5,18.9c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.3-1.3-2.2c-0.3-0.8-0.5-1.7-0.5-2.6
          c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.2c0.6-0.6,1.3-1.1,2.1-1.5c0.8-0.4,1.7-0.6,2.6-0.6c0.9,0,1.8,0.2,2.6,0.6
          c0.8,0.4,1.5,0.9,2.1,1.5c0.6,0.6,1,1.3,1.4,2.2c0.3,0.8,0.5,1.7,0.5,2.6c0,0.9-0.2,1.8-0.5,2.6c-0.3,0.8-0.8,1.5-1.3,2.2
          c-0.6,0.6-1.3,1.1-2.1,1.5C173.4,18.7,172.5,18.9,171.5,18.9 M166.8,12.2c0,0.7,0.1,1.4,0.4,2.1c0.3,0.6,0.6,1.2,1,1.7
          c0.4,0.5,0.9,0.9,1.5,1.1c0.6,0.3,1.2,0.4,1.9,0.4s1.3-0.1,1.9-0.4c0.6-0.3,1.1-0.7,1.5-1.1c0.4-0.5,0.8-1,1-1.7
          c0.3-0.6,0.4-1.3,0.4-2.1c0-0.7-0.1-1.4-0.4-2.1c-0.3-0.6-0.6-1.2-1-1.7c-0.4-0.5-0.9-0.9-1.5-1.1c-0.6-0.3-1.2-0.4-1.9-0.4
          s-1.3,0.1-1.8,0.4c-0.6,0.3-1.1,0.7-1.5,1.1c-0.4,0.5-0.8,1.1-1,1.7C166.9,10.7,166.8,11.4,166.8,12.2"/>
        <path d="M192.6,18.7h-1.7v-7.3c0-1.6-0.2-2.7-0.7-3.4c-0.5-0.7-1.2-1.1-2.1-1.1c-0.5,0-1,0.1-1.5,0.3
          c-0.5,0.2-1,0.4-1.4,0.8c-0.4,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.6,1-0.7,1.5v8h-1.7V5.5h1.6v3c0.5-1,1.3-1.7,2.3-2.3
          c1-0.6,2.1-0.9,3.3-0.9c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.5,1.1,0.7,1.8c0.1,0.7,0.2,1.5,0.2,2.3V18.7z"/>
        <polygon points="92.7,31.1 94.4,31.1 96.9,37.5 99.4,31.1 101.1,31.1 98,38.8 101.4,46.9 107.9,31 109.8,31 
          102.2,48.9 100.7,48.9 96.9,40.1 93.1,48.9 91.6,48.9 84,31 85.9,31 92.4,46.9 95.8,38.8 	"/>
        <path d="M112.6,30.5h1.7V33h-1.7V30.5z M112.6,35.8h1.7v13.1h-1.7V35.8z"/>
        <path d="M117.8,42.3c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.3-2.2c0.6-0.6,1.3-1.1,2.1-1.4c0.8-0.4,1.7-0.5,2.7-0.5
          c1.2,0,2.3,0.3,3.3,0.8s1.6,1.3,2.1,2.3l-1.7,0.5c-0.4-0.7-0.9-1.2-1.6-1.6c-0.7-0.4-1.4-0.6-2.2-0.6c-0.7,0-1.3,0.1-1.9,0.4
          c-0.6,0.3-1.1,0.6-1.5,1.1c-0.4,0.5-0.8,1-1,1.7c-0.2,0.6-0.4,1.3-0.4,2.1c0,0.7,0.1,1.4,0.4,2.1c0.3,0.7,0.6,1.2,1,1.7
          c0.4,0.5,0.9,0.9,1.5,1.1c0.6,0.3,1.2,0.4,1.9,0.4c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.1-0.5c0.4-0.2,0.7-0.4,0.9-0.7
          c0.3-0.3,0.4-0.6,0.5-0.9l1.7,0.5c-0.2,0.5-0.5,0.9-0.8,1.3c-0.4,0.4-0.8,0.7-1.2,1c-0.5,0.3-1,0.5-1.6,0.7
          c-0.6,0.2-1.2,0.2-1.8,0.2c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.4-1.4-2.2C118,44.1,117.8,43.3,117.8,42.3"
          />
        <polygon points="142.6,48.9 137.8,42.2 134.9,44.8 134.9,48.9 133.2,48.9 133.2,30.5 134.9,30.5 134.9,43 142.3,35.8 
          144.3,35.8 138.9,41.2 144.5,48.9 	"/>
        <path d="M152.2,49.2c-1,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1-1.4-1.4-2.2c-0.3-0.8-0.5-1.7-0.5-2.6
          c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1.1,2.1-1.5c0.8-0.4,1.7-0.5,2.6-0.5c1,0,1.8,0.2,2.6,0.5
          c0.8,0.4,1.5,0.9,2.1,1.5c0.6,0.6,1,1.3,1.3,2.1c0.3,0.8,0.5,1.7,0.5,2.6v0.4c0,0.1,0,0.2,0,0.3h-11.2c0.1,0.7,0.2,1.4,0.5,2
          c0.3,0.6,0.6,1.1,1.1,1.6c0.4,0.4,0.9,0.8,1.5,1c0.6,0.2,1.2,0.4,1.8,0.4c0.4,0,0.8-0.1,1.3-0.2c0.4-0.1,0.8-0.3,1.2-0.5
          c0.4-0.2,0.7-0.5,1-0.7c0.3-0.3,0.5-0.6,0.7-1l1.5,0.4c-0.2,0.5-0.5,0.9-0.9,1.3c-0.4,0.4-0.8,0.7-1.3,1c-0.5,0.3-1,0.5-1.6,0.7
          C153.5,49.1,152.8,49.2,152.2,49.2 M157.1,41.7c0-0.7-0.2-1.3-0.5-1.9c-0.3-0.6-0.6-1.1-1.1-1.5c-0.4-0.4-0.9-0.7-1.5-1
          c-0.6-0.2-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1.1,0.6-1.5,1c-0.4,0.4-0.8,0.9-1,1.5c-0.3,0.6-0.4,1.2-0.5,1.9H157.1z
          "/>
        <path d="M168.5,37.3c-1.2,0-2.2,0.4-3,1c-0.9,0.6-1.5,1.4-1.8,2.5v8.2H162V35.8h1.6v3.2c0.5-1,1.1-1.8,2-2.4
          c0.8-0.6,1.7-0.9,2.6-0.9c0.1,0,0.2,0,0.3,0V37.3z"/>
      </g>
    </svg>
  )
}